<template>
	<div class="setting-page">
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">会员权益</div>
            <div style="display: flex; align-items: center;margin-top: 50px;">
                <div v-for="(item,index) in datas[0].photos" :key="index" style="margin-left: 30px;">
                    <img :src="item" alt="" style="width: 200px;height: 200px;">
                </div>
            </div>
			<div class="mt20 bg-w pd40">
				<div class="ft12 ftw400 cl-notice">建议上传240x240px，最多上传9张</div>
                <div class="mt10 ml20">
                    <upload-list v-model="photos" :size="9"></upload-list>
                </div>
                <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
                    上传图片
                </a-button>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import uploadList from '@/components/upload/list.vue';
	export default{
		components:{
			uploadList,
		},
		data(){
			return {
				loading:false,
				photos: [],
                datas: [],
			}
		},
        created(){
            this.getLists()
        },
		methods:{
			onSubmit(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/saveLook',{
					photos:this.photos,
					type: 3,
                    info: 1,
					url: 1,
				}).then(res=>{
					this.$message.success('保存成功');
					this.$router.go(-1);
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
            getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getLookList', {
					limit: 999,
					page: 1,
					type : 3,
				}).then(res => {
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
        }
	}
</script>

<style>
	.setting-page{
		min-width: 1080px;
	}
</style>